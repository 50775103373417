<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>

      <b-col
        cols="12"
        md="10"
        class="px-1"
        order="2"
        order-md="1"
      >
        <b-form-group
          label="Search"
          label-for="filter_pending_search"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_pending_search"
            v-model="tablePaymentVoucher.filter.search"
            type="text"
            size="sm"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        class="px-1"
        order="1"
        order-md="2"
      >
        <b-form-group
          label="Status"
          label-for="filter_status"
          label-class="font-weight-bolder"
        >
          <b-form-select
            id="filter_status"
            v-model="tablePaymentVoucher.filter.status"
            size="sm"
            debounce="1000"
            :options="lists.statuses"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- select status here --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

    </b-row>
    <!-- Table -->
    <b-row
      v-if="currentTabIndex === 0"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tablePaymentVoucher"
          small
          hover
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tablePaymentVoucher.perPage"
          :current-page="tablePaymentVoucher.currentPage"
          :items="tablePaymentVoucherProvider"
          :fields="tablePaymentVoucher.fields"
          :sort-by.sync="tablePaymentVoucher.sortBy"
          :sort-desc.sync="tablePaymentVoucher.sortDesc"
          :sort-direction="tablePaymentVoucher.sortDirection"
          :filter="tablePaymentVoucher.filter"
          :filter-included-fields="tablePaymentVoucher.filterOn"
          :busy="tablePaymentVoucher.busy"
        >
          <template #cell(index)="row">
            {{ tablePaymentVoucher.currentPage * tablePaymentVoucher.perPage - tablePaymentVoucher.perPage + (row.index + 1) }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(voucher_number)="row">
            <div class="text-md-nowrap">
              <b>{{ row.value }}</b>
            </div>
          </template>

          <template #cell(payment_request.amount)="row">
            <div
              class="text-md-nowrap text-md-right"
              style="min-width: 100px;"
            >
              {{ row.value }}
            </div>
          </template>

          <template #cell(action)="{ item }">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
              <b-button
                size="sm"
                :disabled="busy"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                @click="onView(item)"
              >
                View
              </b-button>
              <b-button
                v-if="item.status !== 'Canceled'"
                size="sm"
                variant="outline-danger"
                :disabled="busy"
                @click="onCancel(item)"
              >
                Cancel
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tablePaymentVoucher.perPage"
          size="sm"
          class="w-100 w-md-25"
          :options="tablePaymentVoucher.pageOptions"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tablePaymentVoucher.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tablePaymentVoucher.totalRows"
          :per-page="tablePaymentVoucher.perPage"
        />
      </b-col>

    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { DHPaymentVoucherService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {

  name: 'DhPaymentVouchers',

  mixins: [formatter, misc],

  props: {
    busy: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    currentTabIndex: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },

  data () {
    return {
      lists: {
        statuses: ['All', 'Pending', 'Released', 'Canceled']
      },
      tablePaymentVoucher: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          status: 'All'
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 9, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', label: 'Transaction Date', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'status', label: 'Transaction Status', class: 'text-md-center' },
          { mobile: 3, key: 'voucher_number', label: 'Voucher Number', class: 'text-center', sortable: true },
          { mobile: 4, key: 'payment_request.document_number', label: 'Document Number', class: 'text-center' },
          { mobile: 5, key: 'payment_request.bp_name', label: 'Business Partner' },
          { mobile: 6, key: 'payment_request.customer_name', label: 'Cash Advance' },
          { mobile: 7, key: 'payment_request.mop', label: 'Mode of Payment' },
          { mobile: 8, key: 'payment_request.amount', label: 'Amount' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tablePaymentVoucherProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePaymentVoucher.busy = true
      return await DHPaymentVoucherService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_status: filter.status
        })
      ).then(({ data }) => {
        this.tablePaymentVoucher.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tablePaymentVoucher.busy = false
      })
    },

    onView (paymentRequest) {
      this.$emit('onView', paymentRequest.payment_request, paymentRequest)
    },

    onCancel (voucher) {
      this.swalConfirmWithRemarks({
        html: (
          `<div>
            <p>Mark this Voucher as Canceled?</p>
            <strong class="text-danger">${voucher.voucher_number}</strong>
          </div>`
        ),
        confirmButtonText: 'Mark as Canceled',
        cancelButtonText: 'Discard',
        remarksRequired: true,
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        },
        preConfirm: remarks => this.onPut({
          id: voucher.id,
          canceled_remarks: remarks,
          status: 'Canceled'
        }).then(({ message }) => {
          this.tablePaymentVoucherRefresh()
          this.swalSuccess(message)
        })
      })
    },

    onPut (paymentRequest) {
      return new Promise((resolve, reject) => {
        DHPaymentVoucherService.put(paymentRequest).then(
          ({ data }) => resolve(data)
        ).catch(
          error => reject(error.message)
        )
      })
    },

    tablePaymentVoucherRefresh () {
      this.$refs.tablePaymentVoucher.refresh()
    }

  }

}
</script>
